<template>
  <div class="flexCol alignItems">
    <div class="content edit-page-mb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>看板</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <step-one ref="stepOne"/>
        <step-two ref="stepTwo"/>
        <step-three ref="stepThree"/>
        <step-four ref="stepFour"/>
        <step-five v-if="false" ref="stepFive"/>
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from "./components/step-one";
import StepTwo from "./components/step-two";
import StepThree from "./components/step-three";
import StepFour from "./components/step-four";
import StepFive from "./components/step-five";
export default {
  components: {StepFive, StepFour, StepThree, StepTwo, StepOne},
  data() {
    return {
      tableLoading: false,
      btnLoading: false,
      noticeInfoAdd: false,
      noticeInfoDel: false,
      noticeInfoUpd: false,
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.$refs.stepOne.getData();
      _this.$refs.stepFour.getData();
      // _this.$refs.stepFive.getData();

    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "noticeInfo:add") {
          _this.noticeInfoAdd = true;
        }
        if (v == "noticeInfo:del") {
          _this.noticeInfoDel = true;
        }
        if (v == "noticeInfo:upd") {
          _this.noticeInfoUpd = true;
        }
      })
    },
  }
};
</script>
<style scoped lang="less">
.content_li {
  background-color: rgba(0,0,0,0);
}

</style>
