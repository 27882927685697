<template>
  <div class="card-wrap">
    <div class="top-search">
      <div class="card-header-title">商品销量统计</div>
      <div class="flexRow justifyContentSpac">
        <div class="flexRow">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="monthrange"
                v-model="datePicker"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
            </el-date-picker>
          </div>
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">商品名称：</div>
            <el-input v-model="search.commodityName" placeholder="搜索"></el-input>
          </div>
          <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
          <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>

        </div>
        <div class="flexRow">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </div>
      </div>
    </div>

    <div class="card-main" v-if="false">
      <div class="card-main-tip" v-for="(item,index) in list" :key="index">
        <i class="item"></i>
        <span>{{ item }}</span>
      </div>
    </div>

    <div class="card-echarts">
      <el-table
          border
          :data="tableList"
          v-loading="tableLoading"
          style="width: 100%"
          :header-cell-style="this.utils.getRowClass"
      >
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column align="center" prop="commodityName" label="商品名称" min-width="200" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="sale" label="销量" sortable>
          <template slot-scope="scope">{{scope.row.sale ? scope.row.sale : 0}}</template>
        </el-table-column>
        <el-table-column align="center" prop="exchangeCount" label="换货数" sortable></el-table-column>
        <el-table-column align="center" prop="refundCount" label="退货数" sortable></el-table-column>
        <el-table-column align="center" prop="exchangeRate" label="换货率" sortable>
          <template slot-scope="scope">{{scope.row.exchangeRate && scope.row.exchangeRate !==0 ? `${scope.row.exchangeRate.toFixed(2)}%` : 0}}</template>
        </el-table-column>
        <el-table-column align="center" prop="refundRate" label="退货率" sortable>
          <template slot-scope="scope">{{scope.row.refundRate && scope.row.refundRate !==0 ? `${scope.row.refundRate.toFixed(2)}%` : 0}}</template>
        </el-table-column>
      </el-table>
      <div class="flexRow justifyContentSpac" style="margin-top:20px">
        <div></div>
        <el-pagination
            @size-change="handleSizeChange"
            background
            layout="total, prev, pager, next,sizes"
            :total="total"
            :page-sizes="[10, 15, 20, 30, 50, 100]"
            :page-size="pageSize"
            @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import base from "../../../../../api/api";
export default {
  name: 'stepFour',
  data() {
    return {
      datePicker: [],
      list: ['销售单数', '换货单数', '退货单数'],
      search: {
        startDate: '',
        endDate: '',
        commodityName: '',
      },
      total: 0,
      page: 1,
      pageSize: 10,
      tableList: [],
      tableLoading: false,
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    getData() {
      let _this = this;

      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }

      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }
      // const filterData = JSON.parse(JSON.stringify(_this.search));
      // for (let key in filterData) {
      //   if (filterData[key]) {
      //     if(key === 'search') {
      //       for (let childKey in filterData[key]) {
      //         if(filterData[key][childKey]) {
      //           requestData.search[key] = filterData[key][childKey];
      //         }
      //       }
      //     } else {
      //       requestData[key] = filterData[key];
      //     }
      //   }
      // }

      _this.$api
          .getGoodsSales(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 导出
    handleExport(){
      let _this = this;
      _this.$confirm('确定要导出数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        ancelButtonClass:'cancelClass',
        confirmButtonClass:'confirmClass',
        type: 'warning'
      }).then(()=>{

        let url = `${base.url}/common/data/exportCommoditySale?page=1&pageSize=`+_this.total + '&startDate=' + _this.search.startDate + '&endDate=' + _this.search.endDate;
        window.open(url)
      })
    },
    // 时间
    changePicker(e) {
      let _this = this;
      _this.search.startDate = e[0];
      _this.search.endDate = e[1];
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        startDate: '',
        endDate: '',
        commodityName: '',
      }
      _this.datePicker = [];
      _this.page = 1;
      _this.getData();
    }
  }
};
</script>
<style scoped lang="less">
.card-wrap {
  background-color: #fff;
  padding: 10px 30px;
  margin: 30px 0;
  font-size: 16px;
}

.card-header-title {
  margin-bottom: 20px;
}
</style>
