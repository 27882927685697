<template>
  <div class="card-wrap">
    <div class="card" :style="{'backgroundColor':item.color}" v-for="(item,index) in list" :key="index">
      <img v-if="index === 0" src="@/assets/img/order1.png" alt/>
      <img v-if="index === 1" src="@/assets/img/order2.png" alt/>
      <img v-if="index === 2" src="@/assets/img/order3.png" alt/>
      <img v-if="index === 3" src="@/assets/img/order4.png" alt/>
      <div class="card-right">
        <div>
          <span class="number">{{ item.number }}</span>
          <span class="unit">{{ item.unit }}</span>
        </div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stepOne',
  data() {
    return {
      list: [
        {number:0,unit:'单',title:'今日订单数',color:'#F56C6C'},
        {number:0,unit:'单',title:'累计订单数',color:'#E6A23C'},
        {number:0,unit:'元',title:'今日销售金额',color:'#67C23A'},
        {number:0,unit:'元',title:'累计销售金额',color:'#409EFF'},
      ],
    };
  },
  methods: {
    getData() {
      let _this = this;
      _this.$api.getTodayOrderInfo().then(res => {
            if (res.data.code == 200) {
              const data = res.data.data;
              _this.list[0].number = data.todayOrderCount;
              _this.list[1].number = data.orderTotalCount;
              _this.list[2].number = (data.todaySaleroom/100).toFixed(2);
              _this.list[3].number = (data.totalSaleroom/100).toFixed(2);
            }
          }).catch(() => {
      });
    },
  }
};
</script>
<style scoped lang="less">
.card-wrap {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  width: 20%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 30px;
  box-sizing: border-box;
}

.card > img {
  width: 70px;
  height: 70px;
}
.card-right {
  flex: 1;
  margin-left: 20px;
}
.number{
  font-size: 30px;
}
.unit{
  font-size: 20px;
}
.title{
  font-size: 20px;
  margin-top: 10px;
}
</style>
