<template>
  <div class="card-wrap">
    <div class="top-search">
      <div class="card-header-title">销售金额统计</div>
      <div class="flexRow justifyContentSpac">
        <div class="flexRow">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="monthrange"
                v-model="datePicker"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
            </el-date-picker>
          </div>
        </div>
        <div class="flexRow">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </div>
      </div>
    </div>

    <div class="card-echarts">
      <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
      <div id="echarts" style="width: 100%;height:400px;"></div>
    </div>
  </div>
</template>

<script>
import base from "../../../../../api/api";

const echarts = require("echarts")
export default {
  name: 'stepThree',
  data() {
    return {
      datePicker: [],
      search: {
        startDate: '',
        endDate: '',
      },
      tableLoading:false,
    };
  },
  mounted() {
    this.getECharts();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {}
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData[key] = filterData[key];
        }
      }

      _this.$api.getSaleData(requestData).then(res => {
        if (res.data.code == 200) {
          _this.changeData(res.data.data);
          _this.tableLoading = false;
        }
      }).catch(() => {_this.tableLoading = false;});
    },
    // 处理数据
    changeData(val) {
      let _this = this;

      const data = JSON.parse(JSON.stringify(val));
      const xAxis = []; // 横向数据
      const series = {
        saleTotalPrice: [], // 销售单价
        price: [], // 客单价
      }; // 所有数据

      // 处理销售金额数据
      data.forEach(item => {
        const date = item.resultDate.split("-");
        item.month = Number(date[1]);
        item.year = date[0];
      })
      data.sort(_this.sortIdAsc);
      data.forEach(item => {
        xAxis.push(item.resultDate);
        series.saleTotalPrice.push((item.saleTotalPrice/100).toFixed(2));
        series.price.push((item.price/100).toFixed(2));
      })

      _this.getOption(xAxis, series);
    },
    // 从小到大 升序排序
    sortIdAsc(a, b) {
      return a.month - b.month
    },
    // 指定图表的配置项和数据
    getOption(xAxisData, seriesData) {
      let _this = this;

      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['销售金额'],
          left: '3%',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '销售金额',
            type: 'line',
            data: seriesData.saleTotalPrice
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      _this.echarts.setOption(option);
    },
    getECharts() {
      // 基于准备好的dom，初始化echarts实例
      let _this = this;
      _this.echarts = echarts.init(document.getElementById('echarts'));
      _this.getData();
    },
    // 导出
    handleExport() {
      let _this = this;
      _this.$confirm('确定要导出数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        ancelButtonClass: 'cancelClass',
        confirmButtonClass: 'confirmClass',
        type: 'warning'
      }).then(() => {
        let url = '';
        if (_this.search.endDate) {
          url = `${base.url}/common/data/exportSalesData?endDate=`  + _this.search.endDate + '&startDate=' + _this.search.startDate;
        } else {
          url = `${base.url}/common/data/exportSalesData`
        }
        window.open(url)
      })
    },
    // 时间
    changePicker(e) {
      let _this = this;
      if(e) {
        _this.search.startDate = e[0];
        _this.search.endDate = e[1];
        _this.getData();
      }else {
        _this.search = {
          startDate: '',
          endDate: '',
        }
        _this.datePicker = [];
        _this.getData();
      }
    },
  }
};
</script>
<style scoped lang="less">
.card-wrap {
  background-color: #fff;
  padding: 10px 30px;
  margin: 30px 0;
  font-size: 16px;
}

.card-header-title {
  margin-bottom: 20px;
}
</style>
