<template>
  <div class="card-wrap">
    <div class="top-search">
      <div class="card-header-title">订单数量查询</div>
      <div class="flexRow justifyContentSpac">
        <div class="flexRow">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="monthrange"
                v-model="datePicker"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="flexRow">
          <el-button type="primary" @click="handleSee">查看所有订单</el-button>
          <el-button type="primary" @click="handleExport">导出</el-button>
        </div>
      </div>
    </div>

    <div class="card-main" v-if="false">
      <div class="card-main-tip" v-for="(item,index) in list" :key="index">
        <i class="item"></i>
        <span>{{ item }}</span>
      </div>
    </div>

    <div class="card-echarts">
      <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
      <div id="main" style="width: 100%;height:400px;"></div>
    </div>
  </div>
</template>

<script>
import base from "../../../../../api/api";

const echarts = require("echarts")
export default {
  name: 'stepTwo',
  data() {
    return {
      datePicker: [],
      list: ['销售单数', '换货单数', '退货单数'],
      search: {
        startDate: null,
        endDate: null,
      },
      tableLoading: false,
    };
  },
  mounted() {
    this.getECharts();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {}
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData[key] = filterData[key];
        }
      }

      _this.$api.getOrderData(requestData).then(res => {
        if (res.data.code == 200) {
          _this.changeData(res.data.data);
          _this.tableLoading = false;
        }
      }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 处理数据
    changeData(val) {
      let _this = this;

      const data = JSON.parse(JSON.stringify(val));
      const sales = data.sales; // 销售
      const exchanges = data.exchanges; // 换货
      const refunds = data.refunds; // 退货
      const xAxis = []; // 横向数据
      const series = {
        sales: [],
        exchanges: [],
        refunds: []
      }; // 所有数据

      // 处理销售单数数据
      sales.forEach(item => {
        const date = item.resultDate.split("-");
        item.month = Number(date[1]);
        item.year = date[0];
      })
      sales.sort(_this.sortIdAsc);
      sales.forEach(item => {
        xAxis.push(item.resultDate);
        series.sales.push(item.saleOrderCount);
      })

      // 处理换货单数数据
      exchanges.forEach(item => {
        const date = item.resultDate.split("-");
        item.month = Number(date[1]);
        item.year = date[0];
      })
      exchanges.sort(_this.sortIdAsc);
      exchanges.forEach(item => {
        series.exchanges.push(item.exchangeOrderCount);
      })

      // 处理退货单数数据
      refunds.forEach(item => {
        const date = item.resultDate.split("-");
        item.month = Number(date[1]);
        item.year = date[0];
      })
      refunds.sort(_this.sortIdAsc);
      refunds.forEach(item => {
        series.refunds.push(item.refundOrderCount);
      })

      _this.getOption(xAxis, series);
    },
    // 从小到大 升序排序
    sortIdAsc(a, b) {
      return a.month - b.month
    },
    // 指定图表的配置项和数据
    getOption(xAxisData, seriesData) {
      let _this = this;

      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['销售单数', '换货单数', '退货单数'],
          left: '3%',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '销售单数',
            type: 'line',
            data: seriesData.sales
          },
          {
            name: '换货单数',
            type: 'line',
            data: seriesData.exchanges
          },
          {
            name: '退货单数',
            type: 'line',
            data: seriesData.refunds
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      _this.main.setOption(option);
    },
    getECharts() {
      // 基于准备好的dom，初始化echarts实例
      let _this = this;
      _this.main = echarts.init(document.getElementById('main'));
      _this.getData();
    },
    // 查看所有订单
    handleSee() {
      let _this = this;
      _this.$router.push('/orderList')
    },
    // 导出
    handleExport() {
      let _this = this;
      _this.$confirm('确定要导出数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        ancelButtonClass: 'cancelClass',
        confirmButtonClass: 'confirmClass',
        type: 'warning'
      }).then(() => {
        let url = '';
        if (_this.search.endDate) {
          url = `${base.url}/common/data/exportOrderData?endDate=`  + _this.search.endDate + '&startDate=' + _this.search.startDate;
        } else {
          url = `${base.url}/common/data/exportOrderData`
        }
        window.open(url)
      })
    },
    // 时间
    changePicker(e) {
      let _this = this;
      if (e) {
        _this.search.startDate = e[0];
        _this.search.endDate = e[1];
        _this.getData();
      } else {
        _this.search = {
          startDate: null,
          endDate: null,
        }
        _this.datePicker = [];
        _this.getData();
      }
    },
  }
};
</script>
<style scoped lang="less">
.card-wrap {
  background-color: #fff;
  padding: 10px 30px;
  margin: 30px 0;
  font-size: 16px;
}

.card-header-title {
  margin-bottom: 20px;
}

</style>
