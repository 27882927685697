<template>
  <div class="card-wrap">
    <div class="top-search">
      <div class="card-header-title">用户数据统计</div>
      <div class="card-header-card">
        <div class="card" :style="{'backgroundColor':item.color}" v-for="(item,index) in list" :key="index">
          <img v-if="index === 0" src="@/assets/img/order1.png" alt/>
          <img v-if="index === 1" src="@/assets/img/order2.png" alt/>
          <img v-if="index === 2" src="@/assets/img/order3.png" alt/>
          <div class="card-right">
            <div>
              <span class="number">{{ item.number }}</span>
              <span class="unit">{{ item.unit }}</span>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="flexRow justifyContentSpac" v-if="false">
        <div class="flexRow">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="monthrange"
                v-model="datePicker"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
            </el-date-picker>
          </div>
        </div>
        <div class="flexRow">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </div>
      </div>
    </div>

    <div class="five-content">
      <div class="content-left">
        <div class="content-title">渠道引流统计</div>
        <div class="flexRow justifyContentSpac">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="monthrange"
                v-model="datePicker"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
            </el-date-picker>
          </div>
          <div class="flexRow alignItems margin-btn">
            <div class="search-title" style="width: 120px;">渠道名称：</div>
            <el-input v-model="search.name" placeholder="搜索"></el-input>
          </div>
        </div>

        <div class="table-wrap">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column align="center" prop="signDay" label="渠道"></el-table-column>
            <el-table-column align="center" prop="rewardPoints" label="引流数"></el-table-column>
            <el-table-column align="center" prop="extraBonus" label="注册数"></el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>

      </div>
      <div class="content-right">
        <div class="content-title pl-3">留存数</div>
        <div id="fiveEcharts" style="width: 100%;height:400px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts")
export default {
  name: 'stepFive',
  data() {
    return {
      list: [
        {number:1000,unit:'人',title:'今日渠道引流总数',color:'#F56C6C'},
        {number:1000,unit:'人',title:'今日新增用户数',color:'#E6A23C'},
        {number:1000,unit:'%',title:'今日转化率',color:'#67C23A'}
      ],
      datePicker: [],
      search: {
        startData: '',
        endData: '',
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 10,
      tableList: [],
      tableLoading:false,
    };
  },
  mounted() {
    // this.getData();
    this.getECharts();
  },
  methods: {
    getECharts() {
      // 基于准备好的dom，初始化echarts实例
      let _this = this;
      _this.fiveEcharts = echarts.init(document.getElementById('fiveEcharts'));

      // 指定图表的配置项和数据
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['留存数','流失数'],
          left: '3%',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '留存数',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210,220, 182, 191, 234, 290]
          },
          {
            name: '流失数',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210,220, 182, 191, 234, 290]
          },
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      _this.fiveEcharts.setOption(option);
    },
    getData() {
      let _this = this;

      return
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      _this.$api
          .getNoticeInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    // 查看所有订单
    handleSee() {
    },
    // 导出
    handleExport() {
    },
    // 时间
    changePicker(e) {
      let _this = this;
      _this.search.startData = e[0];
      _this.search.endData = e[1];
    },
  }
};
</script>
<style scoped lang="less">
.card-wrap {
  background-color: #fff;
  padding: 10px 30px;
  margin: 30px 0 100px 0;
  font-size: 16px;
}

.card-header-title {
  margin-bottom: 20px;
}
.card-main {
  display: flex;
}
.card-main> .card-main-tip {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.card-main> .card-main-tip > .item {
  position: relative;
  width: 50px;
}

.card-main .card-main-tip > .item:before {
  content: ''; /*CSS伪类用法*/
  position: absolute; /*定位背景横线的位置*/
  top: 0;
  //background: #409EFF; /*宽和高做出来的背景横线*/
  width: 30px;
  height: 2px;
}
.card-main .card-main-tip:nth-child(1) > .item:before {
  background: #409EFF; /*宽和高做出来的背景横线*/
}
.card-main .card-main-tip:nth-child(2) > .item:before {
  background: #67C23A; /*宽和高做出来的背景横线*/
}
.card-main .card-main-tip:nth-child(3) > .item:before {
  background: #494949; /*宽和高做出来的背景横线*/
}
.card-header-card {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .card {
    width: 20%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 30px;
    box-sizing: border-box;
  }

  .card > img {
    width: 70px;
    height: 70px;
  }
  .card-right {
    flex: 1;
    margin-left: 20px;
  }
  .number{
    font-size: 30px;
  }
  .unit{
    font-size: 20px;
  }
  .title{
    font-size: 20px;
    margin-top: 10px;
  }
}
.five-content {
  display: flex;
  justify-content: space-between;
  flex: 7;
}
.content-left {
  flex: 3;
}
.content-right {
  flex: 4;
  //width: 50%;
}
.content-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.table-wrap {
  margin-top: 20px;
}
</style>
